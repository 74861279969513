import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import Partners from "../shared/top/Partners"
import HeadingM from "../shared/HeadingM"

const query = graphql`
  query {
    aimhigh: file(relativePath: { eq: "shared/partners/aimhigh.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
  }
`

const TopPartners = () => {
  const data = useStaticQuery(query)
  const items = [
    {
      logo: getImage(data.aimhigh),
      link: "https://aimhighgroup.jp/",
    },
  ]
  return (
    <div className="mt-24">
      <HeadingM title="提携事業所" target="noone" />
      <Partners items={items} />
    </div>
  )
}

export default TopPartners
