import { StaticImage } from "gatsby-plugin-image"
import React, { VFC } from "react"
import { getBgImage, getBgWavePc, getBgWaveSp, Target } from "../../../utils"

type Props = {
  isDo?: boolean
  target: Target
}

const About: VFC<Props> = ({ target, isDo }) => {
  return (
    <div
      style={{
        margin: `4rem calc(50% - 50vw + 8px) 0`,
      }}
    >
      <div
        className={`h-4 bg-repeat-x md:h-8 ${getBgWaveSp(target)} ${getBgWavePc(
          target
        )}`}
      ></div>
      <div className={`bg-repeat-round ${getBgImage(target)}`}>
        <div className="max-w-[960px] mx-auto px-4">
          <div
            className={`${isDo ? "flex flex-col-reverse grid-cols-2 text-center md:grid pb-28 pt-28" : "hidden"}`
            }
          >
            <StaticImage
              src="../../../images/shared/rainbow.png"
              alt="rainbow"
              className="mx-auto w-72"
            />
            <div>
              <p className="text-2xl md:text-3xl font-kiwiMaru">
                就労継続支援B型とは？
              </p>
              <div className="mt-10 text-left">
                「就労継続支援B型」は、一般就労が困難な人などに、働く機会が提供される障害福祉サービスです。
                利用者は、年齢や体力面などで障害がある場合でも事業所のサポートのうえ、作業内容や勤務時間などを調整しながら生産活動をすることができます。
                将来的に仕事に就くために必要な知識や能力の向上を目指します。
              </div>
            </div>
          </div>
          <div
            className={`${isDo ? "hidden" : "flex flex-col-reverse grid-cols-2 text-center md:grid pb-28 pt-28"
              }`}
          >
            <StaticImage
              src="../../../images/shared/rainbow.png"
              alt="rainbow"
              className="mx-auto w-72"
            />
            <div>
              <p className="text-2xl md:text-3xl font-kiwiMaru">
                放課後等デイサービスとは
              </p>
              <div className="mt-10 text-left">
                児童福祉法に基づく障がい通所支援事業です。学齢期における支援充実のため設立された制度であり、学校に就学している発達に凸凹がある子ども達が放課後や長期休業中の療育の場として利用でき、ご家族に代わり一時的にケアを代替えする支援サービスの役割を担います。
                原則として　就学児童　が対象となっております。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`h-4 rotate-180 bg-repeat-x md:h-8 ${getBgWaveSp(
          target
        )} ${getBgWavePc(target)}`}
      ></div>
    </div>
  )
}

export default About
