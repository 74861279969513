import React from "react"
import { VFC } from "react"

type Props = {
  bgDotes: string
  name: string
}

const Concept: VFC<Props> = ({ name, bgDotes }) => {
  return (
    <div className="relative font-kiwiMaru">
      <div
        className={`p-8 my-12 md:my-20 text-xl leading-relaxed text-center bg-repeat-round md:text-2xl ${bgDotes} md:leading-relaxed`}
      >
        {name}では <br className="hidden md:block" />
        子どもたちの安心し楽しく過ごせる居場所づくりを大切にし、
        <br className="hidden md:block" />
        たくさん誉め言葉がもらえる場所・出来た体験やできる
        <br className="hidden md:block" />
        自信を蓄える場所を目指しています。
      </div>
    </div>
  )
}

export default Concept
